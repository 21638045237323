import ApiClient from './api'
import Inbox from './notifications'
import { SuprSendError } from './utils'
import { notificationStore, configurationStore } from './store'
import mitt from 'mitt'
import {
  IActionObject,
  IRemoteNotification,
  IRemoteNotificationMessage,
  IStore,
  IStateStore
} from './types'

export default class SuprSendInbox {
  public workspaceKey: string = ''
  public tenantId?: string
  public stores?: IStore[]

  public distinctId?: string
  public subscriberId?: string

  private _client?: ApiClient

  public feed = new Inbox(this)
  public emitter = mitt()

  constructor(
    workspaceKey: string,
    config?: { pageSize?: number; tenantID?: string; stores?: IStore[] }
  ) {
    this._validateConfig(workspaceKey)
    this.tenantId = config?.tenantID || 'default'
    this._validateStore(config?.stores)
    this._validatePageSize(config?.pageSize)
    this._initializeStoreState()
  }

  private _validateConfig(workspaceKey: string) {
    if (!workspaceKey) {
      throw new SuprSendError('workspaceKey missing')
    } else {
      this.workspaceKey = workspaceKey
    }
  }

  private _validateStore(stores?: IStore[]) {
    if (!stores || !Array.isArray(stores)) return
    const validatedStores: IStore[] = []

    stores.forEach((store) => {
      if (!store.storeId) {
        console.log('SuprSendInbox: storeId is mandatory if stores are used')
        return
      }
      const query = store?.query
      let read: boolean | undefined
      let tags: string[] | undefined = []
      let categories: string[] | undefined = []

      if (typeof query?.read === 'boolean') {
        read = query.read
      }

      if (typeof query?.tags === 'string') {
        tags = [query.tags]
      } else if (Array.isArray(query?.tags)) {
        tags = query?.tags.filter((tag) => {
          return typeof tag === 'string'
        })
      }

      if (typeof query?.categories === 'string') {
        categories = [query.categories]
      } else if (Array.isArray(query?.categories)) {
        categories = query?.categories.filter((category) => {
          return typeof category === 'string'
        })
      }

      validatedStores.push({
        storeId: store.storeId,
        label: store.label || store.storeId,
        query: {
          read,
          tags,
          categories
        }
      })
    })
    this.stores = validatedStores
  }

  private _validatePageSize(pageSize?: number) {
    if (pageSize && typeof pageSize === 'number') {
      const MAX_ALLOWED_PAGE_SIZE = 50
      const validatedPageSize =
        pageSize <= MAX_ALLOWED_PAGE_SIZE ? pageSize : MAX_ALLOWED_PAGE_SIZE

      configurationStore.setState({ pageSize: validatedPageSize })
    }
  }

  private _initializeStoreState() {
    const default_store = 'default_store'
    const initialStoreData = {
      notifications: [],
      unseenCount: 0,
      isFirstCall: true
    }

    if (this.stores && Array.isArray(this.stores) && this.stores.length > 0) {
      const finalStores: { [store_id: string]: IStateStore } = {}
      this.stores.map((store) => {
        finalStores[store.storeId] = { ...initialStoreData }
      })
      notificationStore.setState({
        stores: finalStores,
        activeStoreId: this.stores[0].storeId
      })
    } else {
      notificationStore.setState({
        stores: {
          [default_store]: { ...initialStoreData }
        },
        activeStoreId: default_store
      })
    }

    // needed as wait for even listener to register after initialization
    setTimeout(() => {
      this.emitter.emit('sync_notif_store')
    }, 0)
  }

  get client() {
    if (this.distinctId && this.subscriberId) {
      if (!this._client) {
        this._client = new ApiClient(this)
      }
    } else {
      // console.log(
      //   "SuprSend: Can't initialize ApiClient when distinctId/subscriberId are not present"
      // )
    }
    return this._client
  }

  identifyUser(distinctId?: string, subscriberId?: string) {
    this.distinctId = distinctId
    this.subscriberId = subscriberId
  }

  changeActiveStore(storeId: string) {
    const storeData = notificationStore.getState()
    const newStores: { [store_id: string]: IStateStore } = {}

    if (storeData.activeStoreId === storeId) return
    if (!storeData.stores?.[storeId]) return

    for (let storeId in storeData.stores) {
      const store = storeData.stores[storeId]
      const storeNotifications = store.notifications.slice(0, 20)
      newStores[storeId] = {
        notifications: storeNotifications,
        unseenCount: store.unseenCount,
        isFirstCall: store.isFirstCall
      }
    }

    notificationStore.setState({
      activeStoreId: storeId,
      stores: newStores,
      pageNumber: 1,
      hasNext: true,
      initialLoading: false,
      fetchMoreLoading: false,
      initialFetchTime: null
    })
    if (this.client) {
      this.feed.fetchNotifications()
    }
  }

  resetUser() {
    notificationStore.setState({
      unseenCount: 0,
      pageNumber: 1,
      hasNext: true,
      initialLoading: false,
      fetchMoreLoading: false,
      initialFetchTime: null
    })
    this._initializeStoreState()

    this._client?.socket.disconnect()

    this.distinctId = undefined
    this.subscriberId = undefined
    this._client = undefined

    this.feed = new Inbox(this)
  }
}

export type {
  IActionObject,
  IRemoteNotification,
  IRemoteNotificationMessage,
  IStore
}
