import { createStore } from 'zustand/vanilla'
import { IConfigStore, INotificationStore } from './types'

export const configurationStore = createStore<IConfigStore>()(() => ({
  apiUrl: 'https://inboxs.live',
  collectorApiUrl: 'https://hub.suprsend.com',
  socketUrl: 'https://betainbox.suprsend.com',
  pageSize: 20
}))

export const initialNotificationStore: INotificationStore = {
  unseenCount: 0,
  stores: {},
  activeStoreId: null,
  pageNumber: 1,
  hasNext: true,
  initialLoading: false,
  fetchMoreLoading: false,
  initialFetchTime: null
}

export const notificationStore = createStore<INotificationStore>()(
  () => initialNotificationStore
)
